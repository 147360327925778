<template>
  <div>
    <div id="countTypeSwitcher" class="rfs-m-4-b">
      <button
        type="button"
        class="btn btn-primary rfs-m-2-r"
        :class="[
          {
            active: !visitsLoading && countTypeSelected === 'visits'
          }
        ]"
        @click="countTypeSelection('visits')"
        :disabled="visitsLoading"
      >
        <span data-pop-builder-encounters-btn-test>Encounters</span>
        <loader
          loaderStyle="bar"
          :zIndex="0"
          loaderWidth="25px"
          v-if="visitsLoading"
        />
      </button>
      <button
        type="button"
        class="btn btn-primary rfs-m-2-r"
        :class="[
          {
            active: !patientsLoading && countTypeSelected === 'patients'
          }
        ]"
        @click="countTypeSelection('patients')"
        :disabled="patientsLoading"
      >
        <span data-pop-builder-patients-btn-test>Patients</span>
        <loader
          loaderStyle="bar"
          :zIndex="0"
          loaderWidth="25px"
          v-if="patientsLoading"
        />
      </button>
      <button
        type="button"
        class="btn btn-primary rfs-m-2-r"
        :class="[
          {
            active: !diagnosisLoading && countTypeSelected === 'diagnosis'
          }
        ]"
        @click="countTypeSelection('diagnosis')"
        :disabled="diagnosisLoading"
      >
        <span data-pop-builder-encounters-btn-test>Diagnosis Insights</span>
        <loader
          loaderStyle="bar"
          :zIndex="0"
          loaderWidth="25px"
          v-if="diagnosisLoading"
        />
      </button>
    </div>

    <div
      data-test="diagnosisTabContent"
      id="diagnosisTabContent"
      v-if="countTypeSelected === 'diagnosis'"
    >
      <!-- Hiding this top encounters bar until we fix the numbers A.H. -->
      <div class="btn-group btn-group-xlg" v-if="false">
        <input
          type="radio"
          name="patientStatus"
          id="allPatientDiagnosisVisits"
          value="inpatient,outpatient"
          v-model="filterPatientStatusFlatness"
        />
        <label class="flex-1" for="allPatientDiagnosisVisits">
          <div class="xxl:flex items-center gap-2 justify-center">
            <dt class="calloutLabel">Total Encounters</dt>
            <dd class="callout">
              <!-- prettier-ignore -->
              <animated-number
                data-total-encounters-test
                :number="diagnosisInsightsCountsVisits.all | formatNumber({ format: 'precision', places: 0 })"
                :numberFormat="{ format: 'largeNumberAbbr' }"
              ></animated-number
              >
            </dd>
          </div>
        </label>
        <input
          type="radio"
          name="patientStatus"
          id="inpatientDiagnosisStatusVisits"
          value="inpatient"
          v-model="filterPatientStatusFlatness"
        />
        <label class="flex-1" for="inpatientDiagnosisStatusVisits">
          <div class="xxl:flex items-center gap-2 justify-center">
            <dt class="calloutLabel">Inpatient</dt>
            <dd class="callout">
              <!-- prettier-ignore -->
              <animated-number
                data-inpatient-encounters-test
                :number="diagnosisInsightsCountsVisits.inpatient | formatNumber({ format: 'precision', places: 0 })"
                :numberFormat="{ format: 'largeNumberAbbr' }"
              ></animated-number
              >
            </dd>
          </div>
        </label>
        <input
          type="radio"
          name="patientStatus"
          id="outpatientDiagnosisStatusVisits"
          value="outpatient"
          v-model="filterPatientStatusFlatness"
        />
        <label class="flex-1" for="outpatientDiagnosisStatusVisits">
          <div class="xxl:flex items-center gap-2 justify-center">
            <dt class="calloutLabel">Outpatient</dt>
            <dd class="callout">
              <!-- prettier-ignore -->
              <animated-number
                data-outpatient-encounters-test
                :number="diagnosisInsightsCountsVisits.outpatient | formatNumber({ format: 'precision', places: 0 })"
                :numberFormat="{ format: 'largeNumberAbbr' }"
              ></animated-number
              >
            </dd>
          </div>
        </label>

        <loader v-if="diagnosisLoading"/>
      </div>

      <diagnosis-insights-table
        v-if="!diagnosisLoading"
        :filterPatientStatus="filterPatientStatus"
        :ageRangeChoices="ageRangeChoices"
        :genderChoices="genderChoices"
        :countData="patientsFiltered"
        :patientStatusCountsPatients="patientStatusCountsPatients"
        :totalEncounters="patientStatusCountsPatients.all"
        class="rfs-m-6-t"
        :ndcCodes="getUpdatedNdcs"
        :dataSegments = "getDataSegments"
      ></diagnosis-insights-table>
    </div>

    <!-- prettier-ignore -->
    <div
      data-test="visitsTabContent"
      id="visitsTabContent" v-if="countTypeSelected === 'visits'"
    >
    <!-- In Visits  -->
       <!-- needs to be made into a component -->
      <div class="btn-group btn-group-xlg" v-if="!visitsLoading">
        <input
          type="radio"
          name="patientStatus"
          id="allPatientStatussVisits"
          value="inpatient,outpatient"
          v-model="filterPatientStatusFlatness"
        />
        <label class="flex-1" for="allPatientStatussVisits">
          <div class="xxl:flex items-center gap-2 justify-center">
            <dt class="calloutLabel">Total Encounters</dt>
            <dd class="callout">
              <!-- prettier-ignore -->
              <animated-number
                data-total-encounters-test
                :number="patientStatusCountsVisits.all | formatNumber({ format: 'precision', places: 0 })"
                :numberFormat="{ format: 'largeNumberAbbr' }"
              ></animated-number
              >
            </dd>
          </div>
        </label>
        <input
          type="radio"
          name="patientStatus"
          id="inpatientPatientStatusVisits"
          value="inpatient"
          v-model="filterPatientStatusFlatness"
        />
        <label class="flex-1" for="inpatientPatientStatusVisits">
          <div class="xxl:flex items-center gap-2 justify-center">
            <dt class="calloutLabel">Inpatient</dt>
            <dd class="callout">
              <!-- prettier-ignore -->
              <animated-number
                data-inpatient-encounters-test
                :number="patientStatusCountsVisits.inpatient | formatNumber({ format: 'precision', places: 0 })"
                :numberFormat="{ format: 'largeNumberAbbr' }"
              ></animated-number
              >
            </dd>
          </div>
        </label>
        <input
          type="radio"
          name="patientStatus"
          id="outpatientPatientStatusVisits"
          value="outpatient"
          v-model="filterPatientStatusFlatness"
        />
        <label class="flex-1" for="outpatientPatientStatusVisits">
          <div class="xxl:flex items-center gap-2 justify-center">
            <dt class="calloutLabel">Outpatient</dt>
            <dd class="callout">
              <!-- prettier-ignore -->
              <animated-number
                data-outpatient-encounters-test
                :number="patientStatusCountsVisits.outpatient | formatNumber({ format: 'precision', places: 0 })"
                :numberFormat="{ format: 'largeNumberAbbr' }"
              ></animated-number
              >
            </dd>
          </div>
        </label>
      </div>
      <visits-charts
        v-if="!visitsLoading"
        :filterPatientStatus="filterPatientStatus"
        :countsLoading="visitsLoading"
        :ageRangeChoices="ageRangeChoices"
        :genderChoices="genderChoices"
        :countData="visitsFiltered"
        countType="visits"
        :genderData="genderDataVisitCount"
        :ageRangeData="ageRangeDataVisits"
        :ageRangeReadmissions="ageRangeReadmissionsVisits"
        :visitsFilteredPatientStatus="visitsFilteredPatientStatus"
        :ageRangeLos="ageRangeLosVisits"
        :ageRangeDataTotals="ageRangeDataVisitsTotals"
        class="rfs-m-6-t"
      ></visits-charts>
      <loader v-if="visitsLoading" />
    </div>
    <div
      data-test="patientsTabContent"
      id="patientsTabContent"
      v-if="countTypeSelected === 'patients'"
    >
      <!-- In Patients -->
      <!-- needs to be made into a component -->
      <div class="btn-group btn-group-xlg" v-if="!patientsLoading">
        <input
          type="radio"
          name="patientStatus"
          id="allPatientStatussPatients"
          value="inpatient,outpatient"
          v-model="filterPatientStatusFlatness"
        />
        <label class="flex-1" for="allPatientStatussPatients">
          <div class="xxl:flex items-center gap-2 justify-center">
            <dt class="calloutLabel">Total Patients</dt>
            <dd class="callout">
              <!-- prettier-ignore -->
              <animated-number
                data-total-patients-test
                :number="patientStatusCountsPatients.all | formatNumber({ format: 'precision', places: 0 })"
                :numberFormat="{ format: 'largeNumberAbbr' }"
              ></animated-number
              >
            </dd>
          </div>
        </label>
        <input
          type="radio"
          name="patientStatus"
          id="inpatientPatientStatusPatients"
          value="inpatient"
          v-model="filterPatientStatusFlatness"
        />
        <label class="flex-1" for="inpatientPatientStatusPatients">
          <div class="xxl:flex items-center gap-2 justify-center">
            <dt class="calloutLabel">Inpatient</dt>
            <dd class="callout">
              <!-- prettier-ignore -->
              <animated-number
                data-inpatient-patients-test
                :number="patientStatusCountsPatients.inpatient | formatNumber({ format: 'precision', places: 0 })"
                :numberFormat="{ format: 'largeNumberAbbr' }"
              ></animated-number
              >
            </dd>
          </div>
        </label>
        <input
          type="radio"
          name="patientStatus"
          id="outpatientPatientStatusPatients"
          value="outpatient"
          v-model="filterPatientStatusFlatness"
        />
        <label class="flex-1" for="outpatientPatientStatusPatients">
          <div class="xxl:flex items-center gap-2 justify-center">
            <dt class="calloutLabel">Outpatient</dt>
            <dd class="callout">
              <!-- prettier-ignore -->
              <animated-number
                data-outpatient-patients-test
                :number="patientStatusCountsPatients.outpatient | formatNumber({ format: 'precision', places: 0 })"
                :numberFormat="{ format: 'largeNumberAbbr' }"
              ></animated-number
              >
            </dd>
          </div>
        </label>
      </div>
      <patients-charts
        v-if="!patientsLoading"
        :filterPatientStatus="filterPatientStatus"
        :ageRangeChoices="ageRangeChoices"
        :genderChoices="genderChoices"
        :countData="patientsFiltered"
        :patientStatusCountsPatients="patientStatusCountsPatients"
        :genderData="genderDataPatientCount"
        :ageRangeData="ageRangeDataPatients"
        :totalEncounters="patientStatusCountsPatients.all"
        class="rfs-m-6-t"
      ></patients-charts>
      <loader v-if="patientsLoading" />
    </div>
  </div>
</template>

<script>
  import AnimatedNumber from '@/components/base/animated_number.vue';
  import { filterArray } from '@/js/utilities';
  import {
    calculateGenderData,
    calculateAgeRangeByGenderData,
    calculateAgeRangeReadmissions,
    calculateAgeRangeLos,
    calculateAgeRangeData
  } from '@/data';
  import DiagnosisInsightsTable from "@/components/popbuilder/DiagnosisInsightsTable.vue";

  const VisitsCharts = () => import('@/components/popbuilder/VisitsCharts');
  const PatientsCharts = () =>
    import('@/components/popbuilder/PatientsCharts.vue');

  export default {
    name: 'DataCountTabs',
    props: {
      visitsFiltered: Array,
      patientsFiltered: Array,
      ageRangeChoices: Array,
      genderChoices: Array,
      patientStatusChoices: Array,
      visitsLoading: Boolean,
      patientsLoading: Boolean,
      patientStatusCountsVisits: Object,
      patientStatusCountsPatients: Object,
      diagnosisInsightsCountsVisits: Object,
      diagnosisLoading: Boolean,
      ndcCodes: Array
    },
    inject: ['populationWorkflowStore'],
    components: {
      DiagnosisInsightsTable,
      VisitsCharts,
      PatientsCharts,
      AnimatedNumber
    },
    data() {
      return {
        countTypeSelected: 'visits',
        // filterPatientStatus will sync with both visits and patients. So they share the v-model
      filterPatientStatusLocal: ['inpatient', 'outpatient'], // TODO: needs to be something that works from the workflow store
      ndcs: []
      };
    },
    computed: {
    getDataSegments(){
      return this.$store.getters['auth/userDataSegments'].map((dataSegment)=>dataSegment.value);
    },
    getUpdatedNdcs() {
      return this.ndcs;
    },
      patientStatusIncludesInpatient() {
        return (
          this.filterPatientStatus &&
          this.filterPatientStatus.includes('inpatient')
        );
      },
      patientStatusIncludesOutpatient() {
        return (
          this.filterPatientStatus &&
          this.filterPatientStatus.includes('outpatient')
        );
      },
      patientStatusBoth() {
        return (
          this.patientStatusIncludesInpatient &&
          this.patientStatusIncludesOutpatient
        );
      },
      patientStatusEqualsInpatient() {
        return (
          this.patientStatusIncludesInpatient &&
          !this.patientStatusIncludesOutpatient
        );
      },
      patientStatusEqualsOutpatient() {
        return (
          !this.patientStatusIncludesInpatient &&
          this.patientStatusIncludesOutpatient
        );
      },
      filterPatientStatus: {
        get() {
          return this.populationWorkflowStore.getPatientStatus();
        },
        set(value) {
          // The radio buttons are collapsing this, need to make sure it's an array
          let valToSet = value.split(',');

          if (!Array.isArray(valToSet)) {
            valToSet = [valToSet];
          }

          this.populationWorkflowStore.setPatientStatus(valToSet);
        }
      },
      filterPatientStatusFlatness: {
        get() {
          if (Array.isArray(this.filterPatientStatus)) {
            // sort() is destructive so don't want to update the real value here
            let filterPatientStatusTemp = this.filterPatientStatus;
            filterPatientStatusTemp = filterPatientStatusTemp.sort().join(',');
            if (filterPatientStatusTemp === '') {
              return 'inpatient,outpatient';
            }
            return filterPatientStatusTemp;
          } else {
            return this.filterPatientStatus;
          }
        },
        set(value) {
          this.filterPatientStatus = value;
        }
      },
      visitsFilteredPatientStatus() {
        return filterArray(this.visitsFiltered, {
          patientStatus: (patientStatus) =>
            this.filterPatientStatus.includes(
              patientStatus.toString().toLowerCase()
            )
        });
      },
      genderData() {
        if (this.patientStatusBoth) {
          return calculateGenderData({
            visits: this.visitsFiltered,
            patients: this.patientsFiltered
          });
        } else if (this.patientStatusEqualsInpatient) {
          return calculateGenderData(
            {
              visits: this.visitsFilteredPatientStatus,
              patients: this.patientsFiltered
            },
            {
              sumBy: 'inpatientCount'
            }
          );
        } else if (this.patientStatusEqualsOutpatient) {
          return calculateGenderData(
            {
              visits: this.visitsFilteredPatientStatus,
              patients: this.patientsFiltered
            },
            {
              sumBy: 'outpatientCount'
            }
          );
        }

        return calculateGenderData({
          visits: this.visitsFiltered,
          patients: this.patientsFiltered
        });
      },
      genderDataPatientCount() {
        let ret = [];

        ret[0] = this.genderData.malePatientCount;
        ret[1] = this.genderData.femalePatientCount;

        return ret;
      },
      genderDataVisitCount() {
        let ret = [];

        ret[0] = this.genderData.maleVisitCount;
        ret[1] = this.genderData.femaleVisitCount;

        return ret;
      },
      ageRangeDataPatients() {
        if (this.patientStatusBoth) {
          return calculateAgeRangeByGenderData(this.patientsFiltered);
        } else if (this.patientStatusEqualsInpatient) {
          return calculateAgeRangeByGenderData(this.patientsFiltered, {
            sumBy: 'inpatientCount'
          });
        }
        if (this.patientStatusEqualsOutpatient) {
          return calculateAgeRangeByGenderData(this.patientsFiltered, {
            sumBy: 'outpatientCount'
          });
        }

      return calculateAgeRangeByGenderData(this.patientsFiltered);
    },
    ageRangeDataVisits() {
      if (this.patientStatusBoth) {
        return calculateAgeRangeByGenderData(this.visitsFiltered);
      }
      return calculateAgeRangeByGenderData(this.visitsFilteredPatientStatus);
    },
    ageRangeDataVisitsTotals() {
      if (this.patientStatusBoth) {
        return calculateAgeRangeData(this.visitsFiltered);
      }
      return calculateAgeRangeData(this.visitsFilteredPatientStatus);
    },
    ageRangeReadmissionsVisits() {
      if (this.patientStatusEqualsInpatient) {
        return calculateAgeRangeReadmissions(this.visitsFilteredPatientStatus);
      }
      return false; // we only care if it's inpatient only
    },
    ageRangeLosVisits() {
      if (this.patientStatusBoth) {
        return calculateAgeRangeLos(this.visitsFiltered);
      }
      return calculateAgeRangeLos(this.visitsFilteredPatientStatus);
    }
  },
  watch: {
    ndcCodes(newVal) {
      this.ndcs = newVal;
    }
  },
    methods: {
      countTypeSelection(selectionType) {
        this.countTypeSelected = selectionType;
        if (selectionType === 'diagnosis') {
          this.$emit('hideComorbidityAndProcedures');
        } else{
         this.$emit('showComorbidityAndProcedures')
        }
         this.$emit('newPopulationLoading');
      }
    }
};
</script>
